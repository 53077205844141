<template>
  <div>
    <apexchart height="350" type="bar" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
import { computed } from "@vue/composition-api";

export default {
  name: "TodayDriverTripChart",
  props: ["value"],
  setup(props) {
    let chartData = computed(() => (props.value.chartdata != null ? props.value.chartdata : []));

    const categories = computed(() => {
      let x = [];
      chartData.value.forEach(d => x.push(d.driver));
      return x;
    });

    const series = computed(() => {
      let incompletePickup = [];
      let incompleteDelivery = [];

      chartData.value.forEach(d => {
        incompletePickup.push(d.incompletePickupCount);
        incompleteDelivery.push(d.incompleteDeliveryCount);
      });

      return [
        {
          name: "Incomplete Pickup",
          data: incompletePickup
        },
        {
          name: "Incomplete Delivery",
          data: incompleteDelivery
        }
      ];
    });

    const chartOptions = computed(() => {
      return {
        chart: {
          id: "driver-trip-chart",
          type: "bar",
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20%",
            endingShape: "rounded"
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: categories.value
        },
        fill: {
          opacity: 1
        }
      };
    });

    return {
      chartOptions,
      series
    };
  }
};
</script>
