<template>
  <apexchart height="350" type="donut" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
import { computed } from "@vue/composition-api";

export default {
  name: "TodayTripStatusPieChart",
  props: ["value"],
  setup(props) {
    const chartOptions = {
      chart: {
        id: "today-trip-status-pie"
      },
      labels: ["New", "Ongoing", "Completed", "Failed", "Cancelled", "Inactive"],
      colors: ["#3699FF", "#8950FC", "#1BC5BD", "#F64E60", "#B5B5C3", "#181C32"],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      }
    };

    const series = computed(() => {
      return [
        props.value.newtrip,
        props.value.ongoingtrip,
        props.value.completedtrip,
        props.value.failtrip,
        props.value.canceltrip,
        props.value.inactivetrip
      ];
    });
    return {
      chartOptions,
      series
    };
  }
};
</script>
