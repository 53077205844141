<template>
  <apexchart height="350" type="bar" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
import { computed } from "@vue/composition-api";

export default {
  name: "TodayTripStatusPieChart",
  props: ["value"],
  setup(props) {
    const getKey = data => {
      return Object.keys(data).sort();
    };

    const getXAxis = computed(() => {
      if (props.value.chartdata) {
        var keys = getKey(props.value.chartdata);
        let xAxisData = [];
        keys.forEach(k => xAxisData.push(k));
        return xAxisData;
      } else {
        return [];
      }
    });

    const chartOptions = computed(() => {
      return {
        chart: {
          id: "due-trip-chart",
          type: "bar",
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20%",
            endingShape: "rounded",
            distributed: true
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: getXAxis.value
        },
        yaxis: {
          title: {
            text: "Number of trips"
          }
        },
        fill: {
          opacity: 1
        }
      };
    });

    const series = computed(() => {
      if (props.value.chartdata) {
        var keys = getKey(props.value.chartdata);
        let seriesData = [];
        keys.forEach(k => seriesData.push(props.value.chartdata[k]));
        return [
          {
            name: "Unassigned trip",
            data: seriesData
          }
        ];
      } else {
        return [
          {
            name: "Unassigned trip",
            data: []
          }
        ];
      }
    });

    return {
      chartOptions,
      series
    };
  }
};
</script>
