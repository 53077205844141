export function getOmsStatus() {
  const allJobStatus = ["OPEN", "CLOSE", "CANCEL"];

  const getColorByJobStatus = value => {
    switch (value) {
      case allJobStatus[0]:
        return "label-info text-dark";
      case allJobStatus[1]:
        return "label-success";
      case allJobStatus[2]:
        return "label-danger";
    }
  };

  const allTripStatus = ["INACTIVE", "NEW", "ONGOING", "COMPLETED", "FAIL", "CANCEL"];
  const getColorByTripStatus = value => {
    switch (value) {
      case allTripStatus[0]:
        return "label-light-grey";
      case allTripStatus[1]:
        return "label-info text-dark";
      case allTripStatus[2]:
        return "label-light-warning";
      case allTripStatus[3]:
        return "label-success";
      case allTripStatus[4]:
        return "label-light-danger";
      case allTripStatus[5]:
        return "label-grey";
    }
  };

  return {
    allJobStatus,
    getColorByJobStatus,

    allTripStatus,
    getColorByTripStatus
  };
}
