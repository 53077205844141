<template>
  <apexchart height="200" type="donut" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
import { computed } from "@vue/composition-api";

export default {
  name: "TodayJobStatusPieChart",
  props: ["value"],
  setup(props) {
    const chartOptions = {
      chart: {
        id: "today-job-status-pie"
      },
      labels: ["Open", "Close", "Cancelled"],
      colors: ["#3699FF", "#1BC5BD", "#B5B5C3"],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      }
    };

    const series = computed(() => {
      return [props.value.openjob, props.value.closejob, props.value.canceljob];
    });
    return {
      chartOptions,
      series
    };
  }
};
</script>
