<template>
  <div>
    <div class="table-responsive" v-if="data != null && data.length > 0">
      <table class="table table-borderless table-vertical-center">
        <thead>
          <tr>
            <th class="p-0" style="min-width: 100px"></th>
            <th class="p-0" style="min-width: 50px"></th>
            <th class="p-0" style="min-width: 125px"></th>
            <th class="p-0" style="min-width: 150px"></th>
            <th class="p-0" style="min-width: 50px"></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="trip in data">
            <tr v-bind:key="trip.id">
              <td class="pl-0">
                <span class="text-dark-75 font-weight-bolder mb-1 font-size-lg"> # {{ trip.jobId }} </span>
              </td>
              <td class="text-right">
                <span class="text-dark-75 font-weight-500">
                  {{ trip.type }}
                </span>
              </td>
              <td class="text-right">
                <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                  {{ trip.date }}
                </span>
                <span class="text-muted font-weight-bold"> {{ trip.fromTime }} - {{ trip.toTime }} </span>
              </td>
              <td class="text-right">
                <span class="text-dark-75 font-weight-500">
                  {{ trip.address }}
                </span>
              </td>
              <td class="text-right">
                <span class="label label-lg label-inline" :class="getColorByTripStatus(trip.status)">
                  {{ trip.status }}
                </span>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div class="text-center" v-else>
      <h5 class="text-muted">There is no due trips</h5>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/composition-api";
import { getOmsStatus } from "@/utils/oms-status";
export default {
  props: ["value"],
  setup(props) {
    const { getColorByTripStatus } = getOmsStatus();

    const data = computed(() => {
      return props.value.tabledata;
    });

    return {
      data,
      getColorByTripStatus
    };
  }
};
</script>
